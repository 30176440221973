@use '../global/media';

.section-main {
    position: relative;

    @include media.screen(tablet) {
        &__inside {
            max-width: 1100px;
            margin: 0 auto;
        }
    }

    @include media.screen(desktop) {
        &__inside {
            max-width: none;
        }

        &__header {
            max-width: 1184px;
            margin: 50px auto 80px;

            &::after {
                display: table;
                clear: both;
                content: '';
            }
        }
    }
}
