/**
 * Responsive audio (see #441)
 */
.audio_container audio {
    max-width: 100%;
}

/**
 * Responsive videos (see #1348)
 */
.video_container video {
    max-width: 100%;
    height: auto;
}

.aspect,
.responsive {
    position: relative;
    height: 0;
}

.aspect iframe,
.responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.aspect--16\:9,
.responsive.ratio-169 {
    padding-bottom: 56.25%;
}

.aspect--16\:10,
.responsive.ratio-1610 {
    padding-bottom: 62.5%;
}

.aspect--21\:9,
.responsive.ratio-219 {
    padding-bottom: 42.8571%;
}

.aspect--4\:3,
.responsive.ratio-43 {
    padding-bottom: 75%;
}

.aspect--3\:2,
.responsive.ratio-32 {
    padding-bottom: 66.6666%;
}
