@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.topic-intro {
    display: block;

    &__left {
        display: none;
    }

    &__right {
        padding: 20px 80px 20px 20px;
        color: #fff;
        @include color.modify(background, header);

        .content-swiper & {
            padding-bottom: 50px;
        }
    }

    &__text {
        position: relative;
        margin: 0 0 15px;
        padding-bottom: 20px;
        font-weight: map.get(font.$font-weight, bold);
        font-size: 19px;
        line-height: 25px;

        &::after {
            position: absolute;
            bottom: 0;
            display: block;
            width: 50px;
            height: 2px;
            background: #fff;
            content: '';
        }
    }

    &__title {
        margin: 0;
        font-size: 14px;
        line-height: 22px;
    }

    @include media.screen(tablet) {
        display: grid;
        grid-template: auto/50% 50%;

        &__left {
            position: relative;
            display: block;
            height: 304px;
            overflow: hidden;

            img {
                position: absolute;
                right: 0;
                width: auto;
                max-width: none;
                height: 304px;
            }
        }

        &__right {
            padding: 50px 30px;
        }

        &__text {
            font-size: 20px;
            line-height: 26px;
        }

        &__title {
            font-size: 15px;
            line-height: 23px;
        }
    }

    @include media.screen(desktop) {
        &__left,
        &__left img {
            height: 440px;

            .layout--home & {
                height: 500px;
            }
        }

        &__right {
            padding: 80px 60px 0;
        }

        &__text {
            max-width: 520px;
            margin-bottom: 30px;
            padding-bottom: 30px;
            font-size: 40px;
            line-height: 52px;

            &::after {
                width: 100px;
                height: 4px;
            }
        }

        &__title {
            max-width: 520px;
            font-size: 22px;
            line-height: 33px;

            br {
                display: none;
            }
        }
    }
}
