@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.section-advice {
    clear: both;
    border-top: 1px solid #b6b6b6;

    &__inside {
        padding: 0 20px;
    }

    &__lead {
        margin: 35px 0 20px;
        color: #7e7e7e;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 24px;
        text-transform: uppercase;
    }

    &__block {
        margin-bottom: 25px;
        padding-bottom: 25px;
        color: #4c4c4c;
        line-height: 30px;
        border-bottom: 1px solid map.get(color.$default, light);

        &:last-of-type {
            border: none;
        }

        a {
            text-decoration: none;
        }
    }

    &__headline {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        color: #414141;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 20px;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 2px;
            background: #414141;
            content: '';
        }
    }

    &__link {
        position: relative;
        display: block;

        @include icon.link(dark) {
            display: inline-block;
            margin-right: 11px;
        }

        &:hover {
            color: #999;
        }
    }

    @include media.screen(tablet) {
        &__inside {
            max-width: 1100px;
            margin: 0 auto;
            padding: 0 35px;
        }

        &__lead {
            margin-top: 50px;
            font-size: 18px;
        }

        &__container {
            display: flex;
        }

        &__block {
            position: relative;
            width: 27%;
            margin-bottom: 50px;
            padding-right: 30px;
            padding-bottom: 1.5em;
            font-size: 14px;
            line-height: 21px;
            border: none;

            &--last {
                width: 19%;
                padding-right: 0;
            }
        }

        &__headline {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 24px;

            &::after {
                width: 30px;
            }
        }

        &__text {
            font-weight: map.get(font.$font-weight, xlight);
        }

        &__link {
            position: absolute;
            bottom: 0;
        }
    }

    @include media.screen(tablet-only) {
        &__link {
            @include icon.link(dark, 5) {
                position: relative;
                top: -1px;
            }
        }
    }

    @include media.screen(desktop) {
        &__inside {
            max-width: 1250px;
        }

        &__lead {
            font-size: 30px;
        }

        &__headline {
            padding-bottom: 30px;
            font-size: 20px;

            &::after {
                width: 50px;
            }
        }

        &__block {
            font-size: 20px;
            line-height: 35px;
        }
    }
}
