@use 'sass:map';
@use '../global/clear';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.section-footer {
    clear: both;
    color: #585858;
    background: map.get(color.$default, light);
    border-bottom: 9px solid #cecece;

    p {
        margin: 0;
    }

    &__inside {
        position: relative;
        padding: 40px 20px 60px;
    }

    &__infodrog {
        padding: 0 0 20px;
        border-bottom: 0.5px solid #535353;

        .content-text {
            margin: 0;
            color: #999;
            font-weight: map.get(font.$font-weight, medium);
            font-size: 14px;
        }

        .content-hyperlink {
            padding: 10px 15px;

            img {
                width: auto;
                height: 76px;
            }
        }
    }

    &__partner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: left;
        padding: 20px 0 0;
        @include clear.after;

        .content-text {
            flex-basis: 100%;
            margin-bottom: 10px;
            color: #999;
            font-weight: map.get(font.$font-weight, light);
            font-size: 14px;
        }

        .content-hyperlink {
            padding: 10px 15px;

            img {
                width: auto;
                height: 40px;
                opacity: 0.55;
            }
        }
    }

    &__navigation {
        padding: 40px 0 0;
        font-size: 16px;

        .content-text {
            margin-top: 0;
            margin-bottom: 5px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            margin: 0;
            padding: 5px 0;
        }

        a,
        strong {
            display: inline-block;
            padding-right: 10px;
            color: #585858;
            font-weight: map.get(font.$font-weight, light);
            text-decoration: none;
            border-bottom: 1px solid #535353;

            &:hover {
                color: #999;
                border-bottom: 1px solid #999;
            }
        }
    }

    &__backlink {
        position: absolute;
        right: 20px;
        bottom: 10px;
        color: #9b9b9b;
        font-weight: map.get(font.$font-weight, light);
        font-size: 12px;

        a {
            font-weight: map.get(font.$font-weight, bold);
        }
    }

    @include media.screen(tablet) {
        &__inside {
            max-width: 1100px;
            margin: 0 auto;
            padding: 40px 40px 60px;

            @include clear.after;
        }

        &__columns {
            display: flex;
        }

        &__infodrog {
            padding: 0 35px 25px 0;
            border-right: 0.5px solid #535353;
            border-bottom: none;

            .content-text {
                margin: 0 0 25px;
                font-size: 16px;
            }

            .content-hyperlink {
                padding: 0;

                img {
                    height: 87px;
                }
            }
        }

        &__partner {
            display: block;
            padding: 0 0 0 35px;

            .content-text {
                margin-bottom: 35px;
                font-size: 16px;
            }

            .content-hyperlink {
                float: left;
                padding: 10px 35px 0 0;
            }
        }

        &__navigation {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
            text-align: right;

            ul,
            li {
                display: inline;
                padding: 0;
            }

            a,
            strong {
                margin-left: 10px;
                padding: 0;
            }
        }

        &__backlink {
            right: 40px;
        }
    }

    @include media.screen(desktop) {
        &__inside {
            max-width: 1400px;
            padding-bottom: 120px;
        }

        &__navigation {
            line-height: 34px;

            a,
            strong {
                margin-left: 30px;
            }
        }

        &__backlink {
            right: 110px;
        }
    }
}
