@use 'sass:map';
@use '../global/color';
@use '../global/icon';
@use '../global/media';

.section-main,
.section-links {
    .content-hyperlink,
    .content-download,
    .content-downloads,
    .ce_rsce_links {
        clear: both;

        ul,
        li,
        p {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        a {
            position: relative;
            display: block;
            padding: 14px 0 10px 47px;
            text-decoration: none;
            border-bottom: 1px solid;
            @include color.layout(color, font);
            @include color.layout(border-bottom-color, font);

            @include icon.link(layout, 7) {
                position: absolute;
                top: 23px;
                left: 4px;
            }
        }
    }

    .content-hyperlink--file,
    .content-download,
    .content-downloads {
        a {
            @include icon.download(layout, 17) {
                top: 17px;
                left: 6px;
            }
        }
    }

    .ce_rsce_links {
        margin: 0 -20px;
        padding: 48px 20px 28px;

        + .ce_rsce_links {
            border-top: 1px solid map.get(color.$default, regular);
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        p {
            margin: 1em 0;
        }

        .image_container {
            padding: 20px 0;
            text-align: center;

            img {
                width: auto;
                height: auto;
                max-height: 220px;
                border: 1px solid map.get(color.$default, light);
            }
        }

        a {
            border-bottom-color: map.get(color.$default, medium) !important;

            &.file {
                @include icon.download(layout, 17) {
                    top: 17px;
                    left: 6px;
                }
            }
        }

        li:first-child a {
            border-top: 1px solid map.get(color.$default, medium);
        }
    }

    @include media.screen(tablet) {
        .content-hyperlink,
        .content-download,
        .content-downloads,
        .ce_rsce_links {
            a {
                padding: 19px 0 16px 60px;
                font-size: 20px;
                line-height: 1.625;

                @include icon.link(layout) {
                    top: 31px;
                }
            }
        }

        .content-hyperlink--file,
        .content-download,
        .content-downloads {
            a {
                @include icon.download(layout) {
                    top: 21px;
                }
            }
        }

        .ce_rsce_links {
            clear: both;
            margin: 0;
            padding: 50px 0;

            .image_container {
                float: left;
                width: 40%;
                padding: 0.5em 0;

                img {
                    max-width: 90%;
                    max-height: 410px;
                }
            }

            .content {
                margin-left: 42%;

                h3 {
                    margin-top: 0;
                }
            }

            a.file {
                @include icon.download(layout) {
                    top: 21px;
                }
            }
        }
    }
}
