@use '../global/media';

.section-intro {
    .layout--home & .mod_article {
        border-bottom: 1px solid #d4d4d4;
    }

    &__text {
        p {
            margin: 0;
            padding: 25px 20px;
            color: #515151;
            font-size: 19px;
            line-height: 28px;
        }
    }

    .swiper-pagination {
        --swiper-pagination-bottom: 15px;
        display: flex;
        padding: 0 20px;
        text-align: start;

        &-bullet {
            --swiper-pagination-bullet-size: 8px;
            --swiper-pagination-bullet-inactive-opacity: 1;
            --swiper-pagination-bullet-horizontal-gap: 0;
            margin-right: 8px !important;
            font-size: 8px;
            line-height: 8px;
            background: transparent;
            border: 1px solid #fff;
            transition: background 1s linear;

            &-active {
                background: #fff;
            }
        }
    }

    @include media.screen(tablet) {
        .mod_article__inside {
            max-width: 1010px;
            margin: 0 auto;
        }

        &__text {
            p {
                padding: 40px 50px;
                font-size: 22px;
                line-height: 34px;
            }
        }

        .swiper-pagination {
            --swiper-pagination-bottom: 25px;
            left: 50%;
            padding: 0 30px;
        }
    }

    @include media.screen(desktop) {
        .mod_article__inside {
            max-width: 1660px;
        }

        &__text {
            p {
                max-width: 1180px;
                margin: 0 auto;
                padding: 40px 0;
                font-size: 30px;
                line-height: 50px;
            }
        }

        .swiper-pagination {
            --swiper-pagination-bottom: 40px;
            padding: 0 60px;

            &-bullet {
                --swiper-pagination-bullet-size: 15px;
                margin-right: 20px !important;
                font-size: 15px;
                line-height: 15px;
            }
        }
    }
}
