@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

// center-align the pagination
.mod_newslist {
    text-align: center;
}

.news-list {
    text-align: left;

    &__item {
        margin-bottom: 60px;
    }

    &__headline {
        margin: 0 !important;

        a {
            text-decoration: none;
        }
    }

    &__meta {
        color: map.get(color.$news, font);
        font-weight: map.get(font.$font-weight, bold);
        font-size: 14px;
        line-height: 20px;
    }

    &__category {
        position: relative;
        font-weight: map.get(font.$font-weight, regular);

        &::before {
            margin: 0 10px 0 8px;
            content: '|';
        }
    }

    &__teaser {
        p {
            margin: 0;
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        margin-top: 1em;
        padding-left: 50px;
        color: map.get(color.$news, font);
        font-weight: map.get(font.$font-weight, regular);
        font-size: 0.8em;

        @include icon.link(news) {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -5px;
        }
    }

    @include media.screen(tablet) {
        &--columns {
            columns: 2;
            column-gap: 60px;
        }

        &__item {
            width: 50%;

            .news-list--columns & {
                width: auto;
                break-inside: avoid;
                page-break-inside: avoid;
            }
        }

        &__meta {
            font-size: 18px;
            line-height: 37px;
        }
    }
}
