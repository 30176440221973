@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.news-navigation {
    margin: 2em auto !important;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    ul {
        width: 100%;
        border-bottom: 1px solid map.get(color.$news, font);
    }

    li {
        margin-bottom: 10px;
        padding: 0 15px;
        text-align: center;
    }

    a,
    strong {
        display: inline-block;
        color: map.get(color.$news, font);
        font-weight: map.get(font.$font-weight, bold);
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 3px solid transparent;
    }

    a:hover,
    a.trail,
    a.tab-active,
    strong {
        border-bottom-color: map.get(color.$news, font);
    }

    @include media.screen(tablet) {
        ul {
            display: flex;
            justify-content: center;
        }

        li {
            margin-bottom: 0;
        }

        a,
        strong {
            display: block;
            font-size: 16px;
            border-bottom-width: 5px;
        }
    }

    @include media.screen(desktop) {
        a,
        strong {
            min-width: 200px;
            padding: 8px 15px;
            font-size: 22px;
            line-height: 54px;
        }
    }
}
