@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.layout--navigation {
    overflow: hidden;
}

body {
    padding-top: 60px;

    @include media.screen(tablet) {
        padding-top: 94px;
    }

    @include media.screen(desktop) {
        padding-top: 150px;
    }
}

.section-header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #d4d4d4;
    transition: top 0.3s linear;

    &--small {
        top: -60px;

        .layout--navigation & {
            top: 0;
        }
    }

    &__inside {
        height: 60px;
    }

    &__logo {
        position: relative;
        top: 16px;
        left: 21px;
        display: block;
        text-align: left;
        text-indent: -999em;
        background-image: url('../../images/logo-sprite.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @include icon.logo(33px);
    }

    &__navtoggle {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 42px;
        height: 42px;
        padding: 20px 10px 0;

        span,
        span::before,
        span::after {
            position: absolute;
            display: block;
            width: 22px;
            height: 1px;
            background: map.get(color.$default, dark);
            border-radius: 1px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            content: '';
        }

        span::before {
            top: -6px;
        }

        span::after {
            bottom: -6px;
        }

        .layout--navigation & {
            span {
                background-color: transparent;

                &::before {
                    top: 0;
                    transform: rotate(45deg);
                }

                &::after {
                    top: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__language {
        position: absolute;
        top: 81px;
        right: 22px;
        z-index: 101;
        display: none;
        border: 1px solid #767676;
        cursor: pointer;

        .layout--navigation & {
            display: block;
        }

        &::after {
            display: table;
            clear: both;
            content: '';
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: none;
            background: map.get(color.$default, light);

            &.active {
                display: block;
                background: map.get(color.$default, light) url('../../images/language-triangle.svg') 80% center
                    no-repeat;
                background-size: 10px 10px;
            }

            a,
            strong {
                display: block;
                padding: 2px 25px 2px 10px;
                color: map.get(color.$default, dark);
                font-weight: map.get(font.$font-weight, regular);
                font-size: 12px;
                line-height: 27px;
                text-decoration: none;
            }
        }

        &:hover {
            li:not(.active) {
                display: block;
                animation: fade-in 0.3s;

                a:hover {
                    color: #fff;
                    background: map.get(color.$default, regular);
                }
            }
        }
    }

    &__search {
        position: absolute;
        top: 73px;
        right: 120px;
        left: 22px;
        z-index: 101;
        display: none;

        .layout--navigation & {
            display: block;
        }
    }

    &__search-field {
        position: relative;
        width: 100%;
        height: 40px;
        margin: 0;
        padding: 0 0 0 40px;
        font-size: 16px;
        line-height: 20px;
        background: transparent url('../../images/search.svg') left center no-repeat;
        background-size: 25px 25px;
        border: 0;
        border-bottom: 0.5px solid map.get(color.$default, regular);
        outline: none;
        appearance: none;
    }

    &__search-button {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 40px;
        height: 40px;
        text-indent: -999em;
        background: transparent;
        border: none;
    }

    &__search-field--focus + &__search-button {
        display: block;
    }

    &__navigation {
        position: absolute;
        inset: 60px 0 0;
        z-index: 100;
        display: none;
        height: calc(100vh - 60px);
        padding-bottom: 50px;
        overflow-y: scroll;
        background: map.get(color.$news, regular);
        border-top: 75px solid #f2f2f2;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        .layout--navigation & {
            display: block;
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        a,
        strong {
            display: block;
            padding: 11px 0;
            color: #fff;
            font-weight: map.get(font.$font-weight, regular);
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
        }

        .page-icon--home {
            display: none;
        }

        .level_1 {
            > li {
                padding: 0 20px;
                border-bottom: 1px solid #fff;

                > a,
                > strong {
                    position: relative;
                    padding: 17px 0;
                    font-weight: map.get(font.$font-weight, bold);
                    font-size: 19px;
                    line-height: 26px;

                    &::before {
                        position: absolute;
                        top: 20px;
                        right: 10px;
                        width: 1px;
                        height: 21px;
                        background: #fff;
                        transform: rotateX(0deg);
                        transition: transform 0.1s linear;
                        content: '';
                    }

                    &::after {
                        position: absolute;
                        top: 30px;
                        right: 0;
                        width: 21px;
                        height: 1px;
                        background: #fff;
                        content: '';
                    }
                }

                &.open {
                    > a,
                    > strong {
                        &::before {
                            transform: rotateX(90deg);
                        }
                    }

                    .level_2 {
                        display: block;
                    }
                }
            }
        }

        .level_2 {
            display: none;

            > li {
                border-top: 1px solid rgb(255 255 255 / 50%);

                > a,
                > strong {
                    position: relative;
                    padding-right: 10px;
                    padding-left: 10px;
                }

                &.submenu {
                    > a,
                    > strong {
                        &::after {
                            position: absolute;
                            top: 15px;
                            right: 2px;
                            width: 8px;
                            height: 14px;
                            background: url('../../images/navigation-triangle.svg') 0 0 no-repeat;
                            transition: transform 0.1s;
                            content: '';
                        }
                    }
                }

                &.open {
                    > a,
                    > strong {
                        &::after {
                            transform: rotateZ(-90deg);
                        }
                    }

                    .level_3 {
                        display: block;
                    }
                }
            }
        }

        .level_3 {
            display: none;

            > li {
                border-top: 1px solid rgb(255 255 255 / 50%);

                > a,
                > strong {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }
    }

    @include media.screen(tablet) {
        text-align: right;

        &--small {
            top: 0;
        }

        &__inside {
            max-width: 1100px;
            height: 94px;
            margin: 0 auto;
        }

        &__logo {
            top: 29px;
            float: left;

            @include icon.logo(39px);
        }

        &__navtoggle {
            top: 25px;
            right: 30px;
        }

        &__language {
            top: 30px;
            right: 100px;
            display: block;
            border: none;
        }

        &__search {
            top: 25px;
            right: 190px;
            left: auto;
            display: block;
        }

        &__search-field {
            width: 40px;
            padding: 0 40px 0 0;
            background-position: right center;
            border: 0;
            border-bottom: 0.5px solid transparent;

            &--focus {
                width: 220px;
                border-bottom: 0.5px solid map.get(color.$default, regular);
            }
        }

        &__search-button {
            right: 0;
            left: auto;
        }

        &__navigation {
            top: 94px;
            right: 0;
            left: auto;
            display: none;
            min-width: 400px;
            height: calc(100vh - 94px);
            text-align: left;
            border-top: none;

            .layout--navigation & {
                display: block;
            }
        }
    }

    @include media.screen(desktop) {
        &__inside {
            max-width: 1660px;
            height: 150px;
            transition: height 0.5s;
        }

        &__logo {
            top: 34px;
            left: 42px;
            transition: all 0.5s;

            @include icon.logo(81px);
        }

        &__language {
            top: 61px;
            right: 50%;
            margin-right: 35px;
            transition: top 0.5s;

            li {
                &.active {
                    background-size: 13px 13px;
                }

                a,
                strong {
                    padding: 2px 35px 2px 15px;
                    font-size: 16px;
                    line-height: 37px;
                }
            }
        }

        &__search {
            top: 57px;
            right: 50%;
            margin-right: 140px;
            transition: top 0.5s;
        }

        &__search-field {
            height: 50px;
            padding-right: 50px;
            background-size: 33px 33px;
        }

        &__navtoggle {
            display: none;
        }

        &__navigation {
            position: static;
            display: block;
            float: right;
            width: 50%;
            height: 100%;
            padding: 60px 0 0;
            overflow: hidden;
            text-align: left;
            transition: padding 0.5s;
            @include color.layout(background, regular); // stylelint-disable-line
            background: map.get(color.$default, light); // stylelint-disable-line

            .page-icon--home {
                display: initial;
            }

            ul,
            li {
                display: inline;
            }

            .level_1 {
                display: flex;
                justify-content: space-between;
                max-width: 700px;

                > li {
                    margin: 0 12px;
                    padding: 5px 0;
                    border: none;
                    border-top: 1.5px solid transparent;
                    transition: border-top-color 0.3s;

                    &.active,
                    &.trail,
                    &:hover {
                        border-top-color: #3c3c3c;
                    }

                    &.page-icon--home {
                        margin: 0 12px 0 35px;
                        border: none;
                    }

                    > a,
                    > strong {
                        display: inline-block;
                        padding: 0;
                        color: #3c3c3c;
                        font-weight: map.get(font.$font-weight, medium);
                        font-size: 18px;
                        line-height: 39px;
                        text-decoration: none;

                        &::before,
                        &::after {
                            content: none;
                        }

                        &.page-icon--home {
                            position: relative;
                            top: -5px;
                            width: 38px;
                            text-indent: -999em;
                            background: url('../../images/icon-home--dark.svg') 0 0 no-repeat;
                            background-size: 38px 39px;
                        }
                    }

                    .layout--seniors &,
                    .layout--relatives &,
                    .layout--experts &,
                    .layout--doctors &,
                    .layout--news & {
                        &.active,
                        &.trail,
                        &:hover {
                            border-top-color: #fff;
                        }

                        > a,
                        > strong {
                            color: #fff;

                            &.page-icon--home {
                                background-image: url('../../images/icon-home.svg');
                            }
                        }
                    }
                }
            }
        }

        &--small {
            .section-header {
                &__inside {
                    height: 100px;
                }

                &__logo {
                    top: 20px;
                    height: 61px;
                    @include icon.logo(61px);
                }

                &__language {
                    top: 30px;
                }

                &__search {
                    top: 28px;
                }

                &__navigation {
                    padding-top: 28px;
                }
            }
        }
    }
}
