@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.section-info {
    color: #fff;
    @include color.layout(background, regular);

    &__inside {
        padding-bottom: 80px;
    }

    &__headline {
        margin: 0;
        padding: 20px;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 19px;

        strong {
            font-weight: map.get(font.$font-weight, bold);
        }

        @include media.screen(tablet) {
            max-width: 1320px;
            margin: 0 auto;
            padding: 60px 30px;
            font-weight: map.get(font.$font-weight, regular);
            font-size: 30px;

            strong {
                font-weight: map.get(font.$font-weight, medium);
            }
        }
    }

    &__navigation {
        margin-top: 10px;
        border-bottom: 1px solid #fff;

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        a,
        strong {
            display: block;
            margin: 0 20px;
            font-weight: map.get(font.$font-weight, regular);
            text-decoration: none;
        }

        .level_1 {
            > li {
                position: relative;

                &.page-icon {
                    &--alcohol {
                        @include icon.topic(alcohol, 21, white) {
                            position: absolute;
                            top: 15px;
                            left: 21px;
                        }
                    }

                    &--drugs {
                        @include icon.topic(drugs, 21, white) {
                            position: absolute;
                            top: 15px;
                            left: 21px;
                        }
                    }

                    &--opiate {
                        @include icon.topic(opiate, 21, white) {
                            position: absolute;
                            top: 15px;
                            left: 21px;
                        }
                    }

                    &--help {
                        @include icon.topic(help, 21, white) {
                            position: absolute;
                            top: 15px;
                            left: 21px;
                        }
                    }
                }

                > a,
                > strong {
                    position: relative;
                    margin: 0;
                    padding: 15px 45px;
                    color: #f9f9f9;
                    font-weight: map.get(font.$font-weight, bold);
                    border-top: 1px solid #fff;

                    &::before {
                        position: absolute;
                        top: 17px;
                        right: 30px;
                        width: 1px;
                        height: 21px;
                        background: #fff;
                        content: '';
                    }

                    &::after {
                        position: absolute;
                        top: 27px;
                        right: 20px;
                        width: 21px;
                        height: 1px;
                        background: #fff;
                        content: '';
                    }
                }

                .level_2 {
                    display: none;

                    > li > a,
                    > li > strong {
                        padding: 10px 25px;
                        color: #ebebeb;
                        border-top: 1px solid rgb(255 255 255 / 50%);
                    }

                    > li.active {
                        @include color.layout(background, dark);

                        > a,
                        > strong {
                            border-color: transparent;
                        }
                    }

                    > li.active + li {
                        > a,
                        > strong {
                            border-color: transparent;
                        }
                    }
                }

                &.open {
                    > a::before,
                    > strong::before {
                        content: none;
                    }

                    .level_2 {
                        display: block;
                        animation: fade-in 0.3s 1;
                    }
                }
            }
        }

        @include media.screen(tablet) {
            position: relative;
            margin: 0 35px;

            .level_1 {
                display: flex;
                justify-content: center;

                > li {
                    position: inherit;
                    display: inherit;

                    &.page-icon--alcohol,
                    &.page-icon--drugs,
                    &.page-icon--opiate,
                    &.page-icon--help {
                        &::before {
                            content: none;
                        }
                    }

                    > a,
                    > strong {
                        position: relative;
                        margin: 0 20px;
                        padding: 15px 10px 15px 50px;
                        font-size: 22px;
                        text-transform: uppercase;
                        border-top: none;
                        border-bottom: 5px solid transparent;

                        &::before,
                        &::after {
                            content: none;
                        }

                        &.page-icon {
                            &--alcohol {
                                @include icon.topic(alcohol, 39, white) {
                                    position: absolute;
                                    top: 8px;
                                    left: 0;
                                }
                            }

                            &--drugs {
                                @include icon.topic(drugs, 39, white) {
                                    position: absolute;
                                    top: 8px;
                                    left: 0;
                                }
                            }

                            &--opiate {
                                @include icon.topic(opiate, 39, white) {
                                    position: absolute;
                                    top: 8px;
                                    left: 0;
                                }
                            }

                            &--help {
                                @include icon.topic(help, 39, white) {
                                    position: absolute;
                                    top: 8px;
                                    left: 0;
                                }
                            }
                        }
                    }

                    .level_2 {
                        position: absolute;
                        top: 100px;
                        right: -30px;
                        left: -30px;
                        display: flex !important;
                        flex-wrap: wrap;
                        place-content: flex-start flex-start;
                        visibility: hidden;

                        > li {
                            align-self: stretch;
                            width: 50%;
                            vertical-align: middle;

                            > a,
                            > strong {
                                display: block;
                                margin: 0 30px;
                                font-weight: map.get(font.$font-weight, regular);
                                transition: background 0.3s;
                            }

                            &:nth-child(-n + 2) {
                                > a,
                                > strong {
                                    border-top: none;
                                    border-bottom: none;
                                }
                            }
                        }

                        > li.active {
                            background: none !important;

                            > a,
                            > strong {
                                @include color.layout(background, dark);
                            }

                            + li {
                                > a,
                                > strong {
                                    border-top-color: rgb(255 255 255 / 50%);
                                }

                                &:hover {
                                    > a,
                                    > strong {
                                        border-top-color: transparent;
                                    }
                                }
                            }

                            + li + li {
                                > a,
                                > strong {
                                    border-top-color: transparent;
                                }
                            }
                        }

                        > li:hover {
                            > a,
                            > strong {
                                border-top-color: transparent;
                                @include color.layout(background, dark);
                            }

                            + li + li {
                                > a,
                                > strong {
                                    border-top-color: transparent;
                                }
                            }
                        }
                    }

                    &.open {
                        > a,
                        > strong {
                            border-color: #fff;
                        }

                        .level_2 {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        @include media.screen(desktop) {
            max-width: 1260px;
            margin: 0 auto;

            .level_1 {
                > li {
                    .level_2 {
                        > li {
                            width: 33%;

                            &:nth-child(-n + 3) {
                                > a,
                                > strong {
                                    border-top: none;
                                    border-bottom: none;
                                }
                            }
                        }

                        > li.active {
                            + li + li {
                                > a,
                                > strong {
                                    border-top-color: rgb(255 255 255 / 50%);
                                }

                                &:hover {
                                    > a,
                                    > strong {
                                        border-top-color: transparent;
                                    }
                                }
                            }

                            + li + li + li {
                                > a,
                                > strong {
                                    border-top-color: transparent;
                                }
                            }
                        }

                        > li:hover {
                            + li + li {
                                > a,
                                > strong {
                                    border-top-color: rgb(255 255 255 / 50%);
                                }

                                &.active {
                                    > a,
                                    > strong {
                                        border-top-color: transparent;
                                    }
                                }
                            }

                            + li + li + li {
                                > a,
                                > strong {
                                    border-top-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
