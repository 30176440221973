@import 'https://use.typekit.net/nim5iqm.css';
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: 99%;
  font-family: inherit;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  width: 100%;
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td,
table th {
  padding: 6px;
}

caption,
th,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: none;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 16px;
}

body {
  color: #212121;
  font-weight: 300;
  font-size: 16px;
  font-family: rival-sans, sans-serif;
  line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #3c3c3c;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  clear: both;
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
}

/* Hide invisible elements */
.invisible,
#footer .mod_form label {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}

.mod_article::after {
  display: block;
  clear: both;
  content: " ";
}

@keyframes fade-in {
  0% {
    display: inherit;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.layout--navigation {
  overflow: hidden;
}

body {
  padding-top: 60px;
}
@media (min-width: 767px) {
  body {
    padding-top: 94px;
  }
}
@media (min-width: 1265px) {
  body {
    padding-top: 150px;
  }
}

.section-header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d4d4d4;
  transition: top 0.3s linear;
}
.section-header--small {
  top: -60px;
}
.layout--navigation .section-header--small {
  top: 0;
}
.section-header__inside {
  height: 60px;
}
.section-header__logo {
  position: relative;
  top: 16px;
  left: 21px;
  display: block;
  text-align: left;
  text-indent: -999em;
  background-image: url("../../images/logo-sprite.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
html[lang=de] .section-header__logo {
  width: 85.5555555556px;
  height: 33px;
  background-position: 0px 0;
  background-size: 318.5925925926px 165px;
}
html[lang=fr] .section-header__logo {
  width: 109.1851851852px;
  height: 33px;
  background-position: -85.5555555556px 0;
  background-size: 318.5925925926px 165px;
}
html[lang=it] .section-header__logo {
  width: 123.8518518519px;
  height: 33px;
  background-position: -194.7407407407px 0;
  background-size: 318.5925925926px 165px;
}
html[lang=de] .layout--seniors .section-header__logo {
  background-position: 0px 0px;
}
html[lang=fr] .layout--seniors .section-header__logo {
  background-position: -85.5555555556px 0px;
}
html[lang=it] .layout--seniors .section-header__logo {
  background-position: -194.7407407407px 0px;
}
html[lang=de] .layout--relatives .section-header__logo {
  background-position: 0px -33px;
}
html[lang=fr] .layout--relatives .section-header__logo {
  background-position: -85.5555555556px -33px;
}
html[lang=it] .layout--relatives .section-header__logo {
  background-position: -194.7407407407px -33px;
}
html[lang=de] .layout--experts .section-header__logo {
  background-position: 0px -66px;
}
html[lang=fr] .layout--experts .section-header__logo {
  background-position: -85.5555555556px -66px;
}
html[lang=it] .layout--experts .section-header__logo {
  background-position: -194.7407407407px -66px;
}
html[lang=de] .layout--doctors .section-header__logo {
  background-position: 0px -99px;
}
html[lang=fr] .layout--doctors .section-header__logo {
  background-position: -85.5555555556px -99px;
}
html[lang=it] .layout--doctors .section-header__logo {
  background-position: -194.7407407407px -99px;
}
html[lang=de] .layout--news .section-header__logo {
  background-position: 0px -132px;
}
html[lang=fr] .layout--news .section-header__logo {
  background-position: -85.5555555556px -132px;
}
html[lang=it] .layout--news .section-header__logo {
  background-position: -194.7407407407px -132px;
}
.section-header__navtoggle {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 42px;
  height: 42px;
  padding: 20px 10px 0;
}
.section-header__navtoggle span,
.section-header__navtoggle span::before,
.section-header__navtoggle span::after {
  position: absolute;
  display: block;
  width: 22px;
  height: 1px;
  background: #656565;
  border-radius: 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  content: "";
}
.section-header__navtoggle span::before {
  top: -6px;
}
.section-header__navtoggle span::after {
  bottom: -6px;
}
.layout--navigation .section-header__navtoggle span {
  background-color: transparent;
}
.layout--navigation .section-header__navtoggle span::before {
  top: 0;
  transform: rotate(45deg);
}
.layout--navigation .section-header__navtoggle span::after {
  top: 0;
  transform: rotate(-45deg);
}
.section-header__language {
  position: absolute;
  top: 81px;
  right: 22px;
  z-index: 101;
  display: none;
  border: 1px solid #767676;
  cursor: pointer;
}
.layout--navigation .section-header__language {
  display: block;
}
.section-header__language::after {
  display: table;
  clear: both;
  content: "";
}
.section-header__language ul,
.section-header__language li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-header__language li {
  display: none;
  background: #f1f1f1;
}
.section-header__language li.active {
  display: block;
  background: #f1f1f1 url("../../images/language-triangle.svg") 80% center no-repeat;
  background-size: 10px 10px;
}
.section-header__language li a,
.section-header__language li strong {
  display: block;
  padding: 2px 25px 2px 10px;
  color: #656565;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  text-decoration: none;
}
.section-header__language:hover li:not(.active) {
  display: block;
  animation: fade-in 0.3s;
}
.section-header__language:hover li:not(.active) a:hover {
  color: #fff;
  background: #aaa;
}
.section-header__search {
  position: absolute;
  top: 73px;
  right: 120px;
  left: 22px;
  z-index: 101;
  display: none;
}
.layout--navigation .section-header__search {
  display: block;
}
.section-header__search-field {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 0 0 40px;
  font-size: 16px;
  line-height: 20px;
  background: transparent url("../../images/search.svg") left center no-repeat;
  background-size: 25px 25px;
  border: 0;
  border-bottom: 0.5px solid #aaa;
  outline: none;
  appearance: none;
}
.section-header__search-button {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 40px;
  height: 40px;
  text-indent: -999em;
  background: transparent;
  border: none;
}
.section-header__search-field--focus + .section-header__search-button {
  display: block;
}
.section-header__navigation {
  position: absolute;
  inset: 60px 0 0;
  z-index: 100;
  display: none;
  height: calc(100vh - 60px);
  padding-bottom: 50px;
  overflow-y: scroll;
  background: #20528e;
  border-top: 75px solid #f2f2f2;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.layout--navigation .section-header__navigation {
  display: block;
}
.section-header__navigation ul,
.section-header__navigation li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-header__navigation a,
.section-header__navigation strong {
  display: block;
  padding: 11px 0;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.section-header__navigation .page-icon--home {
  display: none;
}
.section-header__navigation .level_1 > li {
  padding: 0 20px;
  border-bottom: 1px solid #fff;
}
.section-header__navigation .level_1 > li > a,
.section-header__navigation .level_1 > li > strong {
  position: relative;
  padding: 17px 0;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
}
.section-header__navigation .level_1 > li > a::before,
.section-header__navigation .level_1 > li > strong::before {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 1px;
  height: 21px;
  background: #fff;
  transform: rotateX(0deg);
  transition: transform 0.1s linear;
  content: "";
}
.section-header__navigation .level_1 > li > a::after,
.section-header__navigation .level_1 > li > strong::after {
  position: absolute;
  top: 30px;
  right: 0;
  width: 21px;
  height: 1px;
  background: #fff;
  content: "";
}
.section-header__navigation .level_1 > li.open > a::before,
.section-header__navigation .level_1 > li.open > strong::before {
  transform: rotateX(90deg);
}
.section-header__navigation .level_1 > li.open .level_2 {
  display: block;
}
.section-header__navigation .level_2 {
  display: none;
}
.section-header__navigation .level_2 > li {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.section-header__navigation .level_2 > li > a,
.section-header__navigation .level_2 > li > strong {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}
.section-header__navigation .level_2 > li.submenu > a::after,
.section-header__navigation .level_2 > li.submenu > strong::after {
  position: absolute;
  top: 15px;
  right: 2px;
  width: 8px;
  height: 14px;
  background: url("../../images/navigation-triangle.svg") 0 0 no-repeat;
  transition: transform 0.1s;
  content: "";
}
.section-header__navigation .level_2 > li.open > a::after,
.section-header__navigation .level_2 > li.open > strong::after {
  transform: rotateZ(-90deg);
}
.section-header__navigation .level_2 > li.open .level_3 {
  display: block;
}
.section-header__navigation .level_3 {
  display: none;
}
.section-header__navigation .level_3 > li {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.section-header__navigation .level_3 > li > a,
.section-header__navigation .level_3 > li > strong {
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 767px) {
  .section-header {
    text-align: right;
  }
  .section-header--small {
    top: 0;
  }
  .section-header__inside {
    max-width: 1100px;
    height: 94px;
    margin: 0 auto;
  }
  .section-header__logo {
    top: 29px;
    float: left;
  }
  html[lang=de] .section-header__logo {
    width: 101.1111111111px;
    height: 39px;
    background-position: 0px 0;
    background-size: 376.5185185185px 195px;
  }
  html[lang=fr] .section-header__logo {
    width: 129.037037037px;
    height: 39px;
    background-position: -101.1111111111px 0;
    background-size: 376.5185185185px 195px;
  }
  html[lang=it] .section-header__logo {
    width: 146.3703703704px;
    height: 39px;
    background-position: -230.1481481481px 0;
    background-size: 376.5185185185px 195px;
  }
  html[lang=de] .layout--seniors .section-header__logo {
    background-position: 0px 0px;
  }
  html[lang=fr] .layout--seniors .section-header__logo {
    background-position: -101.1111111111px 0px;
  }
  html[lang=it] .layout--seniors .section-header__logo {
    background-position: -230.1481481481px 0px;
  }
  html[lang=de] .layout--relatives .section-header__logo {
    background-position: 0px -39px;
  }
  html[lang=fr] .layout--relatives .section-header__logo {
    background-position: -101.1111111111px -39px;
  }
  html[lang=it] .layout--relatives .section-header__logo {
    background-position: -230.1481481481px -39px;
  }
  html[lang=de] .layout--experts .section-header__logo {
    background-position: 0px -78px;
  }
  html[lang=fr] .layout--experts .section-header__logo {
    background-position: -101.1111111111px -78px;
  }
  html[lang=it] .layout--experts .section-header__logo {
    background-position: -230.1481481481px -78px;
  }
  html[lang=de] .layout--doctors .section-header__logo {
    background-position: 0px -117px;
  }
  html[lang=fr] .layout--doctors .section-header__logo {
    background-position: -101.1111111111px -117px;
  }
  html[lang=it] .layout--doctors .section-header__logo {
    background-position: -230.1481481481px -117px;
  }
  html[lang=de] .layout--news .section-header__logo {
    background-position: 0px -156px;
  }
  html[lang=fr] .layout--news .section-header__logo {
    background-position: -101.1111111111px -156px;
  }
  html[lang=it] .layout--news .section-header__logo {
    background-position: -230.1481481481px -156px;
  }
  .section-header__navtoggle {
    top: 25px;
    right: 30px;
  }
  .section-header__language {
    top: 30px;
    right: 100px;
    display: block;
    border: none;
  }
  .section-header__search {
    top: 25px;
    right: 190px;
    left: auto;
    display: block;
  }
  .section-header__search-field {
    width: 40px;
    padding: 0 40px 0 0;
    background-position: right center;
    border: 0;
    border-bottom: 0.5px solid transparent;
  }
  .section-header__search-field--focus {
    width: 220px;
    border-bottom: 0.5px solid #aaa;
  }
  .section-header__search-button {
    right: 0;
    left: auto;
  }
  .section-header__navigation {
    top: 94px;
    right: 0;
    left: auto;
    display: none;
    min-width: 400px;
    height: calc(100vh - 94px);
    text-align: left;
    border-top: none;
  }
  .layout--navigation .section-header__navigation {
    display: block;
  }
}
@media (min-width: 1265px) {
  .section-header__inside {
    max-width: 1660px;
    height: 150px;
    transition: height 0.5s;
  }
  .section-header__logo {
    top: 34px;
    left: 42px;
    transition: all 0.5s;
  }
  html[lang=de] .section-header__logo {
    width: 210px;
    height: 81px;
    background-position: 0px 0;
    background-size: 782px 405px;
  }
  html[lang=fr] .section-header__logo {
    width: 268px;
    height: 81px;
    background-position: -210px 0;
    background-size: 782px 405px;
  }
  html[lang=it] .section-header__logo {
    width: 304px;
    height: 81px;
    background-position: -478px 0;
    background-size: 782px 405px;
  }
  html[lang=de] .layout--seniors .section-header__logo {
    background-position: 0px 0px;
  }
  html[lang=fr] .layout--seniors .section-header__logo {
    background-position: -210px 0px;
  }
  html[lang=it] .layout--seniors .section-header__logo {
    background-position: -478px 0px;
  }
  html[lang=de] .layout--relatives .section-header__logo {
    background-position: 0px -81px;
  }
  html[lang=fr] .layout--relatives .section-header__logo {
    background-position: -210px -81px;
  }
  html[lang=it] .layout--relatives .section-header__logo {
    background-position: -478px -81px;
  }
  html[lang=de] .layout--experts .section-header__logo {
    background-position: 0px -162px;
  }
  html[lang=fr] .layout--experts .section-header__logo {
    background-position: -210px -162px;
  }
  html[lang=it] .layout--experts .section-header__logo {
    background-position: -478px -162px;
  }
  html[lang=de] .layout--doctors .section-header__logo {
    background-position: 0px -243px;
  }
  html[lang=fr] .layout--doctors .section-header__logo {
    background-position: -210px -243px;
  }
  html[lang=it] .layout--doctors .section-header__logo {
    background-position: -478px -243px;
  }
  html[lang=de] .layout--news .section-header__logo {
    background-position: 0px -324px;
  }
  html[lang=fr] .layout--news .section-header__logo {
    background-position: -210px -324px;
  }
  html[lang=it] .layout--news .section-header__logo {
    background-position: -478px -324px;
  }
  .section-header__language {
    top: 61px;
    right: 50%;
    margin-right: 35px;
    transition: top 0.5s;
  }
  .section-header__language li.active {
    background-size: 13px 13px;
  }
  .section-header__language li a,
  .section-header__language li strong {
    padding: 2px 35px 2px 15px;
    font-size: 16px;
    line-height: 37px;
  }
  .section-header__search {
    top: 57px;
    right: 50%;
    margin-right: 140px;
    transition: top 0.5s;
  }
  .section-header__search-field {
    height: 50px;
    padding-right: 50px;
    background-size: 33px 33px;
  }
  .section-header__navtoggle {
    display: none;
  }
  .section-header__navigation {
    position: static;
    display: block;
    float: right;
    width: 50%;
    height: 100%;
    padding: 60px 0 0;
    overflow: hidden;
    text-align: left;
    transition: padding 0.5s;
    background: #aaa;
    background: #f1f1f1;
  }
  .layout--seniors .section-header__navigation {
    background: #4178b7;
  }
  .layout--relatives .section-header__navigation {
    background: #c65096;
  }
  .layout--experts .section-header__navigation {
    background: #8b77a2;
  }
  .layout--doctors .section-header__navigation {
    background: #3ea4a8;
  }
  .layout--news .section-header__navigation {
    background: #20528e;
  }
  .section-header__navigation .page-icon--home {
    display: initial;
  }
  .section-header__navigation ul,
  .section-header__navigation li {
    display: inline;
  }
  .section-header__navigation .level_1 {
    display: flex;
    justify-content: space-between;
    max-width: 700px;
  }
  .section-header__navigation .level_1 > li {
    margin: 0 12px;
    padding: 5px 0;
    border: none;
    border-top: 1.5px solid transparent;
    transition: border-top-color 0.3s;
  }
  .section-header__navigation .level_1 > li.active, .section-header__navigation .level_1 > li.trail, .section-header__navigation .level_1 > li:hover {
    border-top-color: #3c3c3c;
  }
  .section-header__navigation .level_1 > li.page-icon--home {
    margin: 0 12px 0 35px;
    border: none;
  }
  .section-header__navigation .level_1 > li > a,
  .section-header__navigation .level_1 > li > strong {
    display: inline-block;
    padding: 0;
    color: #3c3c3c;
    font-weight: 500;
    font-size: 18px;
    line-height: 39px;
    text-decoration: none;
  }
  .section-header__navigation .level_1 > li > a::before, .section-header__navigation .level_1 > li > a::after,
  .section-header__navigation .level_1 > li > strong::before,
  .section-header__navigation .level_1 > li > strong::after {
    content: none;
  }
  .section-header__navigation .level_1 > li > a.page-icon--home,
  .section-header__navigation .level_1 > li > strong.page-icon--home {
    position: relative;
    top: -5px;
    width: 38px;
    text-indent: -999em;
    background: url("../../images/icon-home--dark.svg") 0 0 no-repeat;
    background-size: 38px 39px;
  }
  .layout--seniors .section-header__navigation .level_1 > li.active, .layout--seniors .section-header__navigation .level_1 > li.trail, .layout--seniors .section-header__navigation .level_1 > li:hover, .layout--relatives .section-header__navigation .level_1 > li.active, .layout--relatives .section-header__navigation .level_1 > li.trail, .layout--relatives .section-header__navigation .level_1 > li:hover, .layout--experts .section-header__navigation .level_1 > li.active, .layout--experts .section-header__navigation .level_1 > li.trail, .layout--experts .section-header__navigation .level_1 > li:hover, .layout--doctors .section-header__navigation .level_1 > li.active, .layout--doctors .section-header__navigation .level_1 > li.trail, .layout--doctors .section-header__navigation .level_1 > li:hover, .layout--news .section-header__navigation .level_1 > li.active, .layout--news .section-header__navigation .level_1 > li.trail, .layout--news .section-header__navigation .level_1 > li:hover {
    border-top-color: #fff;
  }
  .layout--seniors .section-header__navigation .level_1 > li > a,
  .layout--seniors .section-header__navigation .level_1 > li > strong, .layout--relatives .section-header__navigation .level_1 > li > a,
  .layout--relatives .section-header__navigation .level_1 > li > strong, .layout--experts .section-header__navigation .level_1 > li > a,
  .layout--experts .section-header__navigation .level_1 > li > strong, .layout--doctors .section-header__navigation .level_1 > li > a,
  .layout--doctors .section-header__navigation .level_1 > li > strong, .layout--news .section-header__navigation .level_1 > li > a,
  .layout--news .section-header__navigation .level_1 > li > strong {
    color: #fff;
  }
  .layout--seniors .section-header__navigation .level_1 > li > a.page-icon--home,
  .layout--seniors .section-header__navigation .level_1 > li > strong.page-icon--home, .layout--relatives .section-header__navigation .level_1 > li > a.page-icon--home,
  .layout--relatives .section-header__navigation .level_1 > li > strong.page-icon--home, .layout--experts .section-header__navigation .level_1 > li > a.page-icon--home,
  .layout--experts .section-header__navigation .level_1 > li > strong.page-icon--home, .layout--doctors .section-header__navigation .level_1 > li > a.page-icon--home,
  .layout--doctors .section-header__navigation .level_1 > li > strong.page-icon--home, .layout--news .section-header__navigation .level_1 > li > a.page-icon--home,
  .layout--news .section-header__navigation .level_1 > li > strong.page-icon--home {
    background-image: url("../../images/icon-home.svg");
  }
  .section-header--small .section-header__inside {
    height: 100px;
  }
  .section-header--small .section-header__logo {
    top: 20px;
    height: 61px;
  }
  html[lang=de] .section-header--small .section-header__logo {
    width: 158.1481481481px;
    height: 61px;
    background-position: 0px 0;
    background-size: 588.9135802469px 305px;
  }
  html[lang=fr] .section-header--small .section-header__logo {
    width: 201.8271604938px;
    height: 61px;
    background-position: -158.1481481481px 0;
    background-size: 588.9135802469px 305px;
  }
  html[lang=it] .section-header--small .section-header__logo {
    width: 228.9382716049px;
    height: 61px;
    background-position: -359.975308642px 0;
    background-size: 588.9135802469px 305px;
  }
  html[lang=de] .layout--seniors .section-header--small .section-header__logo {
    background-position: 0px 0px;
  }
  html[lang=fr] .layout--seniors .section-header--small .section-header__logo {
    background-position: -158.1481481481px 0px;
  }
  html[lang=it] .layout--seniors .section-header--small .section-header__logo {
    background-position: -359.975308642px 0px;
  }
  html[lang=de] .layout--relatives .section-header--small .section-header__logo {
    background-position: 0px -61px;
  }
  html[lang=fr] .layout--relatives .section-header--small .section-header__logo {
    background-position: -158.1481481481px -61px;
  }
  html[lang=it] .layout--relatives .section-header--small .section-header__logo {
    background-position: -359.975308642px -61px;
  }
  html[lang=de] .layout--experts .section-header--small .section-header__logo {
    background-position: 0px -122px;
  }
  html[lang=fr] .layout--experts .section-header--small .section-header__logo {
    background-position: -158.1481481481px -122px;
  }
  html[lang=it] .layout--experts .section-header--small .section-header__logo {
    background-position: -359.975308642px -122px;
  }
  html[lang=de] .layout--doctors .section-header--small .section-header__logo {
    background-position: 0px -183px;
  }
  html[lang=fr] .layout--doctors .section-header--small .section-header__logo {
    background-position: -158.1481481481px -183px;
  }
  html[lang=it] .layout--doctors .section-header--small .section-header__logo {
    background-position: -359.975308642px -183px;
  }
  html[lang=de] .layout--news .section-header--small .section-header__logo {
    background-position: 0px -244px;
  }
  html[lang=fr] .layout--news .section-header--small .section-header__logo {
    background-position: -158.1481481481px -244px;
  }
  html[lang=it] .layout--news .section-header--small .section-header__logo {
    background-position: -359.975308642px -244px;
  }
  .section-header--small .section-header__language {
    top: 30px;
  }
  .section-header--small .section-header__search {
    top: 28px;
  }
  .section-header--small .section-header__navigation {
    padding-top: 28px;
  }
}

.layout--home .section-intro .mod_article {
  border-bottom: 1px solid #d4d4d4;
}
.section-intro__text p {
  margin: 0;
  padding: 25px 20px;
  color: #515151;
  font-size: 19px;
  line-height: 28px;
}
.section-intro .swiper-pagination {
  --swiper-pagination-bottom: 15px;
  display: flex;
  padding: 0 20px;
  text-align: start;
}
.section-intro .swiper-pagination-bullet {
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 0;
  margin-right: 8px !important;
  font-size: 8px;
  line-height: 8px;
  background: transparent;
  border: 1px solid #fff;
  transition: background 1s linear;
}
.section-intro .swiper-pagination-bullet-active {
  background: #fff;
}
@media (min-width: 767px) {
  .section-intro .mod_article__inside {
    max-width: 1010px;
    margin: 0 auto;
  }
  .section-intro__text p {
    padding: 40px 50px;
    font-size: 22px;
    line-height: 34px;
  }
  .section-intro .swiper-pagination {
    --swiper-pagination-bottom: 25px;
    left: 50%;
    padding: 0 30px;
  }
}
@media (min-width: 1265px) {
  .section-intro .mod_article__inside {
    max-width: 1660px;
  }
  .section-intro__text p {
    max-width: 1180px;
    margin: 0 auto;
    padding: 40px 0;
    font-size: 30px;
    line-height: 50px;
  }
  .section-intro .swiper-pagination {
    --swiper-pagination-bottom: 40px;
    padding: 0 60px;
  }
  .section-intro .swiper-pagination-bullet {
    --swiper-pagination-bullet-size: 15px;
    margin-right: 20px !important;
    font-size: 15px;
    line-height: 15px;
  }
}

.section-main {
  position: relative;
}
@media (min-width: 767px) {
  .section-main__inside {
    max-width: 1100px;
    margin: 0 auto;
  }
}
@media (min-width: 1265px) {
  .section-main__inside {
    max-width: none;
  }
  .section-main__header {
    max-width: 1184px;
    margin: 50px auto 80px;
  }
  .section-main__header::after {
    display: table;
    clear: both;
    content: "";
  }
}

.section-links {
  background: #f1f1f1;
  color: #212121;
}
.layout--seniors .section-links {
  color: #294669;
}
.layout--relatives .section-links {
  color: #712a54;
}
.layout--experts .section-links {
  color: #54415d;
}
.layout--doctors .section-links {
  color: #225456;
}
.layout--news .section-links {
  color: #294669;
}
.section-links__inside {
  position: relative;
  padding: 40px 20px 100px;
}
.section-links h1 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 19px;
}
.section-links h1::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 2px;
  content: "";
  background: #212121;
}
.layout--seniors .section-links h1::after {
  background: #294669;
}
.layout--relatives .section-links h1::after {
  background: #712a54;
}
.layout--experts .section-links h1::after {
  background: #54415d;
}
.layout--doctors .section-links h1::after {
  background: #225456;
}
.layout--news .section-links h1::after {
  background: #294669;
}
@media (min-width: 767px) {
  .section-links .mod_article__inside {
    display: flex;
    flex-wrap: wrap;
    padding-top: 120px;
  }
  .section-links h1 {
    position: absolute;
    top: 70px;
    margin: 0 15px;
    font-size: 30px;
  }
  .section-links .content-hyperlink,
  .section-links .content-download,
  .section-links .content-text {
    float: left;
    width: 50%;
    padding: 0 15px;
  }
}
@media (min-width: 1265px) {
  .section-links__inside {
    max-width: 1260px;
    margin: 0 auto;
  }
}

.section-info {
  color: #fff;
  background: #aaa;
}
.layout--seniors .section-info {
  background: #4178b7;
}
.layout--relatives .section-info {
  background: #c65096;
}
.layout--experts .section-info {
  background: #8b77a2;
}
.layout--doctors .section-info {
  background: #3ea4a8;
}
.layout--news .section-info {
  background: #20528e;
}
.section-info__inside {
  padding-bottom: 80px;
}
.section-info__headline {
  margin: 0;
  padding: 20px;
  font-weight: 500;
  font-size: 19px;
}
.section-info__headline strong {
  font-weight: 700;
}
@media (min-width: 767px) {
  .section-info__headline {
    max-width: 1320px;
    margin: 0 auto;
    padding: 60px 30px;
    font-weight: 400;
    font-size: 30px;
  }
  .section-info__headline strong {
    font-weight: 500;
  }
}
.section-info__navigation {
  margin-top: 10px;
  border-bottom: 1px solid #fff;
}
.section-info__navigation ul,
.section-info__navigation li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-info__navigation a,
.section-info__navigation strong {
  display: block;
  margin: 0 20px;
  font-weight: 400;
  text-decoration: none;
}
.section-info__navigation .level_1 > li {
  position: relative;
}
.section-info__navigation .level_1 > li.page-icon--alcohol::before {
  width: 18.3406113537px;
  height: 21px;
  background: url("../../images/topic-sprite.svg") 0px -21px no-repeat;
  background-size: 73.3624454148px 126px;
  content: "";
  position: absolute;
  top: 15px;
  left: 21px;
}
.section-info__navigation .level_1 > li.page-icon--drugs::before {
  width: 18.3406113537px;
  height: 21px;
  background: url("../../images/topic-sprite.svg") -18.3406113537px -21px no-repeat;
  background-size: 73.3624454148px 126px;
  content: "";
  position: absolute;
  top: 15px;
  left: 21px;
}
.section-info__navigation .level_1 > li.page-icon--opiate::before {
  width: 18.3406113537px;
  height: 21px;
  background: url("../../images/topic-sprite.svg") -36.6812227074px -21px no-repeat;
  background-size: 73.3624454148px 126px;
  content: "";
  position: absolute;
  top: 15px;
  left: 21px;
}
.section-info__navigation .level_1 > li.page-icon--help::before {
  width: 18.3406113537px;
  height: 21px;
  background: url("../../images/topic-sprite.svg") -55.0218340611px -21px no-repeat;
  background-size: 73.3624454148px 126px;
  content: "";
  position: absolute;
  top: 15px;
  left: 21px;
}
.section-info__navigation .level_1 > li > a,
.section-info__navigation .level_1 > li > strong {
  position: relative;
  margin: 0;
  padding: 15px 45px;
  color: #f9f9f9;
  font-weight: 700;
  border-top: 1px solid #fff;
}
.section-info__navigation .level_1 > li > a::before,
.section-info__navigation .level_1 > li > strong::before {
  position: absolute;
  top: 17px;
  right: 30px;
  width: 1px;
  height: 21px;
  background: #fff;
  content: "";
}
.section-info__navigation .level_1 > li > a::after,
.section-info__navigation .level_1 > li > strong::after {
  position: absolute;
  top: 27px;
  right: 20px;
  width: 21px;
  height: 1px;
  background: #fff;
  content: "";
}
.section-info__navigation .level_1 > li .level_2 {
  display: none;
}
.section-info__navigation .level_1 > li .level_2 > li > a,
.section-info__navigation .level_1 > li .level_2 > li > strong {
  padding: 10px 25px;
  color: #ebebeb;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.section-info__navigation .level_1 > li .level_2 > li.active {
  background: #656565;
}
.layout--seniors .section-info__navigation .level_1 > li .level_2 > li.active {
  background: #376395;
}
.layout--relatives .section-info__navigation .level_1 > li .level_2 > li.active {
  background: #a4487e;
}
.layout--experts .section-info__navigation .level_1 > li .level_2 > li.active {
  background: #7b6784;
}
.layout--doctors .section-info__navigation .level_1 > li .level_2 > li.active {
  background: #34878b;
}
.layout--news .section-info__navigation .level_1 > li .level_2 > li.active {
  background: #376395;
}
.section-info__navigation .level_1 > li .level_2 > li.active > a,
.section-info__navigation .level_1 > li .level_2 > li.active > strong {
  border-color: transparent;
}
.section-info__navigation .level_1 > li .level_2 > li.active + li > a,
.section-info__navigation .level_1 > li .level_2 > li.active + li > strong {
  border-color: transparent;
}
.section-info__navigation .level_1 > li.open > a::before,
.section-info__navigation .level_1 > li.open > strong::before {
  content: none;
}
.section-info__navigation .level_1 > li.open .level_2 {
  display: block;
  animation: fade-in 0.3s 1;
}
@media (min-width: 767px) {
  .section-info__navigation {
    position: relative;
    margin: 0 35px;
  }
  .section-info__navigation .level_1 {
    display: flex;
    justify-content: center;
  }
  .section-info__navigation .level_1 > li {
    position: inherit;
    display: inherit;
  }
  .section-info__navigation .level_1 > li.page-icon--alcohol::before, .section-info__navigation .level_1 > li.page-icon--drugs::before, .section-info__navigation .level_1 > li.page-icon--opiate::before, .section-info__navigation .level_1 > li.page-icon--help::before {
    content: none;
  }
  .section-info__navigation .level_1 > li > a,
  .section-info__navigation .level_1 > li > strong {
    position: relative;
    margin: 0 20px;
    padding: 15px 10px 15px 50px;
    font-size: 22px;
    text-transform: uppercase;
    border-top: none;
    border-bottom: 5px solid transparent;
  }
  .section-info__navigation .level_1 > li > a::before, .section-info__navigation .level_1 > li > a::after,
  .section-info__navigation .level_1 > li > strong::before,
  .section-info__navigation .level_1 > li > strong::after {
    content: none;
  }
  .section-info__navigation .level_1 > li > a.page-icon--alcohol::before,
  .section-info__navigation .level_1 > li > strong.page-icon--alcohol::before {
    width: 34.0611353712px;
    height: 39px;
    background: url("../../images/topic-sprite.svg") 0px -39px no-repeat;
    background-size: 136.2445414847px 234px;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
  }
  .section-info__navigation .level_1 > li > a.page-icon--drugs::before,
  .section-info__navigation .level_1 > li > strong.page-icon--drugs::before {
    width: 34.0611353712px;
    height: 39px;
    background: url("../../images/topic-sprite.svg") -34.0611353712px -39px no-repeat;
    background-size: 136.2445414847px 234px;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
  }
  .section-info__navigation .level_1 > li > a.page-icon--opiate::before,
  .section-info__navigation .level_1 > li > strong.page-icon--opiate::before {
    width: 34.0611353712px;
    height: 39px;
    background: url("../../images/topic-sprite.svg") -68.1222707424px -39px no-repeat;
    background-size: 136.2445414847px 234px;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
  }
  .section-info__navigation .level_1 > li > a.page-icon--help::before,
  .section-info__navigation .level_1 > li > strong.page-icon--help::before {
    width: 34.0611353712px;
    height: 39px;
    background: url("../../images/topic-sprite.svg") -102.1834061135px -39px no-repeat;
    background-size: 136.2445414847px 234px;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
  }
  .section-info__navigation .level_1 > li .level_2 {
    position: absolute;
    top: 100px;
    right: -30px;
    left: -30px;
    display: flex !important;
    flex-wrap: wrap;
    place-content: flex-start flex-start;
    visibility: hidden;
  }
  .section-info__navigation .level_1 > li .level_2 > li {
    align-self: stretch;
    width: 50%;
    vertical-align: middle;
  }
  .section-info__navigation .level_1 > li .level_2 > li > a,
  .section-info__navigation .level_1 > li .level_2 > li > strong {
    display: block;
    margin: 0 30px;
    font-weight: 400;
    transition: background 0.3s;
  }
  .section-info__navigation .level_1 > li .level_2 > li:nth-child(-n+2) > a,
  .section-info__navigation .level_1 > li .level_2 > li:nth-child(-n+2) > strong {
    border-top: none;
    border-bottom: none;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active {
    background: none !important;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #656565;
  }
  .layout--seniors .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .layout--seniors .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #376395;
  }
  .layout--relatives .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .layout--relatives .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #a4487e;
  }
  .layout--experts .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .layout--experts .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #7b6784;
  }
  .layout--doctors .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .layout--doctors .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #34878b;
  }
  .layout--news .section-info__navigation .level_1 > li .level_2 > li.active > a,
  .layout--news .section-info__navigation .level_1 > li .level_2 > li.active > strong {
    background: #376395;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li > strong {
    border-top-color: rgba(255, 255, 255, 0.5);
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li:hover > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li:hover > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    border-top-color: transparent;
    background: #656565;
  }
  .layout--seniors .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .layout--seniors .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    background: #376395;
  }
  .layout--relatives .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .layout--relatives .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    background: #a4487e;
  }
  .layout--experts .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .layout--experts .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    background: #7b6784;
  }
  .layout--doctors .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .layout--doctors .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    background: #34878b;
  }
  .layout--news .section-info__navigation .level_1 > li .level_2 > li:hover > a,
  .layout--news .section-info__navigation .level_1 > li .level_2 > li:hover > strong {
    background: #376395;
  }
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li.open > a,
  .section-info__navigation .level_1 > li.open > strong {
    border-color: #fff;
  }
  .section-info__navigation .level_1 > li.open .level_2 {
    visibility: visible;
  }
}
@media (min-width: 1265px) {
  .section-info__navigation {
    max-width: 1260px;
    margin: 0 auto;
  }
  .section-info__navigation .level_1 > li .level_2 > li {
    width: 33%;
  }
  .section-info__navigation .level_1 > li .level_2 > li:nth-child(-n+3) > a,
  .section-info__navigation .level_1 > li .level_2 > li:nth-child(-n+3) > strong {
    border-top: none;
    border-bottom: none;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li > strong {
    border-top-color: rgba(255, 255, 255, 0.5);
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li:hover > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li:hover > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li.active + li + li + li > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li > strong {
    border-top-color: rgba(255, 255, 255, 0.5);
  }
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li.active > a,
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li.active > strong {
    border-top-color: transparent;
  }
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li + li > a,
  .section-info__navigation .level_1 > li .level_2 > li:hover + li + li + li > strong {
    border-top-color: transparent;
  }
}

.section-advice {
  clear: both;
  border-top: 1px solid #b6b6b6;
}
.section-advice__inside {
  padding: 0 20px;
}
.section-advice__lead {
  margin: 35px 0 20px;
  color: #7e7e7e;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
}
.section-advice__block {
  margin-bottom: 25px;
  padding-bottom: 25px;
  color: #4c4c4c;
  line-height: 30px;
  border-bottom: 1px solid #f1f1f1;
}
.section-advice__block:last-of-type {
  border: none;
}
.section-advice__block a {
  text-decoration: none;
}
.section-advice__headline {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #414141;
  font-weight: 500;
  font-size: 20px;
}
.section-advice__headline::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background: #414141;
  content: "";
}
.section-advice__link {
  position: relative;
  display: block;
}
.section-advice__link:before {
  background: url("../../images/link-sprite.svg") left 0px no-repeat;
  width: 39px;
  height: 9px;
  background-size: 39px 54px;
  content: "";
  display: inline-block;
  margin-right: 11px;
}
.section-advice__link:hover {
  color: #999;
}
@media (min-width: 767px) {
  .section-advice__inside {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 35px;
  }
  .section-advice__lead {
    margin-top: 50px;
    font-size: 18px;
  }
  .section-advice__container {
    display: flex;
  }
  .section-advice__block {
    position: relative;
    width: 27%;
    margin-bottom: 50px;
    padding-right: 30px;
    padding-bottom: 1.5em;
    font-size: 14px;
    line-height: 21px;
    border: none;
  }
  .section-advice__block--last {
    width: 19%;
    padding-right: 0;
  }
  .section-advice__headline {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  .section-advice__headline::after {
    width: 30px;
  }
  .section-advice__text {
    font-weight: 200;
  }
  .section-advice__link {
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 767px) and (max-width: 1264px) {
  .section-advice__link:before {
    background: url("../../images/link-sprite.svg") left 0px no-repeat;
    width: 21.6666666667px;
    height: 5px;
    background-size: 21.6666666667px 30px;
    content: "";
    position: relative;
    top: -1px;
  }
}
@media (min-width: 1265px) {
  .section-advice__inside {
    max-width: 1250px;
  }
  .section-advice__lead {
    font-size: 30px;
  }
  .section-advice__headline {
    padding-bottom: 30px;
    font-size: 20px;
  }
  .section-advice__headline::after {
    width: 50px;
  }
  .section-advice__block {
    font-size: 20px;
    line-height: 35px;
  }
}

.section-footer {
  clear: both;
  color: #585858;
  background: #f1f1f1;
  border-bottom: 9px solid #cecece;
}
.section-footer p {
  margin: 0;
}
.section-footer__inside {
  position: relative;
  padding: 40px 20px 60px;
}
.section-footer__infodrog {
  padding: 0 0 20px;
  border-bottom: 0.5px solid #535353;
}
.section-footer__infodrog .content-text {
  margin: 0;
  color: #999;
  font-weight: 500;
  font-size: 14px;
}
.section-footer__infodrog .content-hyperlink {
  padding: 10px 15px;
}
.section-footer__infodrog .content-hyperlink img {
  width: auto;
  height: 76px;
}
.section-footer__partner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: left;
  padding: 20px 0 0;
}
.section-footer__partner::after {
  display: table;
  clear: both;
  content: "";
}
.section-footer__partner .content-text {
  flex-basis: 100%;
  margin-bottom: 10px;
  color: #999;
  font-weight: 300;
  font-size: 14px;
}
.section-footer__partner .content-hyperlink {
  padding: 10px 15px;
}
.section-footer__partner .content-hyperlink img {
  width: auto;
  height: 40px;
  opacity: 0.55;
}
.section-footer__navigation {
  padding: 40px 0 0;
  font-size: 16px;
}
.section-footer__navigation .content-text {
  margin-top: 0;
  margin-bottom: 5px;
}
.section-footer__navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-footer__navigation li {
  margin: 0;
  padding: 5px 0;
}
.section-footer__navigation a,
.section-footer__navigation strong {
  display: inline-block;
  padding-right: 10px;
  color: #585858;
  font-weight: 300;
  text-decoration: none;
  border-bottom: 1px solid #535353;
}
.section-footer__navigation a:hover,
.section-footer__navigation strong:hover {
  color: #999;
  border-bottom: 1px solid #999;
}
.section-footer__backlink {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: #9b9b9b;
  font-weight: 300;
  font-size: 12px;
}
.section-footer__backlink a {
  font-weight: 700;
}
@media (min-width: 767px) {
  .section-footer__inside {
    max-width: 1100px;
    margin: 0 auto;
    padding: 40px 40px 60px;
  }
  .section-footer__inside::after {
    display: table;
    clear: both;
    content: "";
  }
  .section-footer__columns {
    display: flex;
  }
  .section-footer__infodrog {
    padding: 0 35px 25px 0;
    border-right: 0.5px solid #535353;
    border-bottom: none;
  }
  .section-footer__infodrog .content-text {
    margin: 0 0 25px;
    font-size: 16px;
  }
  .section-footer__infodrog .content-hyperlink {
    padding: 0;
  }
  .section-footer__infodrog .content-hyperlink img {
    height: 87px;
  }
  .section-footer__partner {
    display: block;
    padding: 0 0 0 35px;
  }
  .section-footer__partner .content-text {
    margin-bottom: 35px;
    font-size: 16px;
  }
  .section-footer__partner .content-hyperlink {
    float: left;
    padding: 10px 35px 0 0;
  }
  .section-footer__navigation {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    text-align: right;
  }
  .section-footer__navigation ul,
  .section-footer__navigation li {
    display: inline;
    padding: 0;
  }
  .section-footer__navigation a,
  .section-footer__navigation strong {
    margin-left: 10px;
    padding: 0;
  }
  .section-footer__backlink {
    right: 40px;
  }
}
@media (min-width: 1265px) {
  .section-footer__inside {
    max-width: 1400px;
    padding-bottom: 120px;
  }
  .section-footer__navigation {
    line-height: 34px;
  }
  .section-footer__navigation a,
  .section-footer__navigation strong {
    margin-left: 30px;
  }
  .section-footer__backlink {
    right: 110px;
  }
}

.topic-intro {
  display: block;
}
.topic-intro__left {
  display: none;
}
.topic-intro__right {
  padding: 20px 80px 20px 20px;
  color: #fff;
}
.topic-intro__right--seniors {
  background: #669ddd;
}
.topic-intro__right--relatives {
  background: #dd75b2;
}
.topic-intro__right--experts {
  background: #ad94cb;
}
.topic-intro__right--doctors {
  background: #5bc4c8;
}
.topic-intro__right--news {
  background: #669ddd;
}
.content-swiper .topic-intro__right {
  padding-bottom: 50px;
}
.topic-intro__text {
  position: relative;
  margin: 0 0 15px;
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
}
.topic-intro__text::after {
  position: absolute;
  bottom: 0;
  display: block;
  width: 50px;
  height: 2px;
  background: #fff;
  content: "";
}
.topic-intro__title {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
}
@media (min-width: 767px) {
  .topic-intro {
    display: grid;
    grid-template: auto/50% 50%;
  }
  .topic-intro__left {
    position: relative;
    display: block;
    height: 304px;
    overflow: hidden;
  }
  .topic-intro__left img {
    position: absolute;
    right: 0;
    width: auto;
    max-width: none;
    height: 304px;
  }
  .topic-intro__right {
    padding: 50px 30px;
  }
  .topic-intro__text {
    font-size: 20px;
    line-height: 26px;
  }
  .topic-intro__title {
    font-size: 15px;
    line-height: 23px;
  }
}
@media (min-width: 1265px) {
  .topic-intro__left, .topic-intro__left img {
    height: 440px;
  }
  .layout--home .topic-intro__left, .layout--home .topic-intro__left img {
    height: 500px;
  }
  .topic-intro__right {
    padding: 80px 60px 0;
  }
  .topic-intro__text {
    max-width: 520px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-size: 40px;
    line-height: 52px;
  }
  .topic-intro__text::after {
    width: 100px;
    height: 4px;
  }
  .topic-intro__title {
    max-width: 520px;
    font-size: 22px;
    line-height: 33px;
  }
  .topic-intro__title br {
    display: none;
  }
}

/**
 * Responsive audio (see #441)
 */
.audio_container audio {
  max-width: 100%;
}

/**
 * Responsive videos (see #1348)
 */
.video_container video {
  max-width: 100%;
  height: auto;
}

.aspect,
.responsive {
  position: relative;
  height: 0;
}

.aspect iframe,
.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect--16\:9,
.responsive.ratio-169 {
  padding-bottom: 56.25%;
}

.aspect--16\:10,
.responsive.ratio-1610 {
  padding-bottom: 62.5%;
}

.aspect--21\:9,
.responsive.ratio-219 {
  padding-bottom: 42.8571%;
}

.aspect--4\:3,
.responsive.ratio-43 {
  padding-bottom: 75%;
}

.aspect--3\:2,
.responsive.ratio-32 {
  padding-bottom: 66.6666%;
}

.section-main .content-hyperlink,
.section-main .content-download,
.section-main .content-downloads,
.section-main .ce_rsce_links,
.section-links .content-hyperlink,
.section-links .content-download,
.section-links .content-downloads,
.section-links .ce_rsce_links {
  clear: both;
}
.section-main .content-hyperlink ul,
.section-main .content-hyperlink li,
.section-main .content-hyperlink p,
.section-main .content-download ul,
.section-main .content-download li,
.section-main .content-download p,
.section-main .content-downloads ul,
.section-main .content-downloads li,
.section-main .content-downloads p,
.section-main .ce_rsce_links ul,
.section-main .ce_rsce_links li,
.section-main .ce_rsce_links p,
.section-links .content-hyperlink ul,
.section-links .content-hyperlink li,
.section-links .content-hyperlink p,
.section-links .content-download ul,
.section-links .content-download li,
.section-links .content-download p,
.section-links .content-downloads ul,
.section-links .content-downloads li,
.section-links .content-downloads p,
.section-links .ce_rsce_links ul,
.section-links .ce_rsce_links li,
.section-links .ce_rsce_links p {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-main .content-hyperlink a,
.section-main .content-download a,
.section-main .content-downloads a,
.section-main .ce_rsce_links a,
.section-links .content-hyperlink a,
.section-links .content-download a,
.section-links .content-downloads a,
.section-links .ce_rsce_links a {
  position: relative;
  display: block;
  padding: 14px 0 10px 47px;
  text-decoration: none;
  border-bottom: 1px solid;
  color: #212121;
  border-bottom-color: #212121;
}
.layout--seniors .section-main .content-hyperlink a,
.layout--seniors .section-main .content-download a,
.layout--seniors .section-main .content-downloads a,
.layout--seniors .section-main .ce_rsce_links a,
.layout--seniors .section-links .content-hyperlink a,
.layout--seniors .section-links .content-download a,
.layout--seniors .section-links .content-downloads a,
.layout--seniors .section-links .ce_rsce_links a {
  color: #294669;
}
.layout--relatives .section-main .content-hyperlink a,
.layout--relatives .section-main .content-download a,
.layout--relatives .section-main .content-downloads a,
.layout--relatives .section-main .ce_rsce_links a,
.layout--relatives .section-links .content-hyperlink a,
.layout--relatives .section-links .content-download a,
.layout--relatives .section-links .content-downloads a,
.layout--relatives .section-links .ce_rsce_links a {
  color: #712a54;
}
.layout--experts .section-main .content-hyperlink a,
.layout--experts .section-main .content-download a,
.layout--experts .section-main .content-downloads a,
.layout--experts .section-main .ce_rsce_links a,
.layout--experts .section-links .content-hyperlink a,
.layout--experts .section-links .content-download a,
.layout--experts .section-links .content-downloads a,
.layout--experts .section-links .ce_rsce_links a {
  color: #54415d;
}
.layout--doctors .section-main .content-hyperlink a,
.layout--doctors .section-main .content-download a,
.layout--doctors .section-main .content-downloads a,
.layout--doctors .section-main .ce_rsce_links a,
.layout--doctors .section-links .content-hyperlink a,
.layout--doctors .section-links .content-download a,
.layout--doctors .section-links .content-downloads a,
.layout--doctors .section-links .ce_rsce_links a {
  color: #225456;
}
.layout--news .section-main .content-hyperlink a,
.layout--news .section-main .content-download a,
.layout--news .section-main .content-downloads a,
.layout--news .section-main .ce_rsce_links a,
.layout--news .section-links .content-hyperlink a,
.layout--news .section-links .content-download a,
.layout--news .section-links .content-downloads a,
.layout--news .section-links .ce_rsce_links a {
  color: #294669;
}
.layout--seniors .section-main .content-hyperlink a,
.layout--seniors .section-main .content-download a,
.layout--seniors .section-main .content-downloads a,
.layout--seniors .section-main .ce_rsce_links a,
.layout--seniors .section-links .content-hyperlink a,
.layout--seniors .section-links .content-download a,
.layout--seniors .section-links .content-downloads a,
.layout--seniors .section-links .ce_rsce_links a {
  border-bottom-color: #294669;
}
.layout--relatives .section-main .content-hyperlink a,
.layout--relatives .section-main .content-download a,
.layout--relatives .section-main .content-downloads a,
.layout--relatives .section-main .ce_rsce_links a,
.layout--relatives .section-links .content-hyperlink a,
.layout--relatives .section-links .content-download a,
.layout--relatives .section-links .content-downloads a,
.layout--relatives .section-links .ce_rsce_links a {
  border-bottom-color: #712a54;
}
.layout--experts .section-main .content-hyperlink a,
.layout--experts .section-main .content-download a,
.layout--experts .section-main .content-downloads a,
.layout--experts .section-main .ce_rsce_links a,
.layout--experts .section-links .content-hyperlink a,
.layout--experts .section-links .content-download a,
.layout--experts .section-links .content-downloads a,
.layout--experts .section-links .ce_rsce_links a {
  border-bottom-color: #54415d;
}
.layout--doctors .section-main .content-hyperlink a,
.layout--doctors .section-main .content-download a,
.layout--doctors .section-main .content-downloads a,
.layout--doctors .section-main .ce_rsce_links a,
.layout--doctors .section-links .content-hyperlink a,
.layout--doctors .section-links .content-download a,
.layout--doctors .section-links .content-downloads a,
.layout--doctors .section-links .ce_rsce_links a {
  border-bottom-color: #225456;
}
.layout--news .section-main .content-hyperlink a,
.layout--news .section-main .content-download a,
.layout--news .section-main .content-downloads a,
.layout--news .section-main .ce_rsce_links a,
.layout--news .section-links .content-hyperlink a,
.layout--news .section-links .content-download a,
.layout--news .section-links .content-downloads a,
.layout--news .section-links .ce_rsce_links a {
  border-bottom-color: #294669;
}
.section-main .content-hyperlink a:before,
.section-main .content-download a:before,
.section-main .content-downloads a:before,
.section-main .ce_rsce_links a:before,
.section-links .content-hyperlink a:before,
.section-links .content-download a:before,
.section-links .content-downloads a:before,
.section-links .ce_rsce_links a:before {
  background: url("../../images/link-sprite.svg") 0 0px no-repeat;
  width: 30.3333333333px;
  height: 7px;
  background-size: 30.3333333333px 42px;
  content: "";
  position: absolute;
  top: 23px;
  left: 4px;
}
.layout--seniors .section-main .content-hyperlink a:before,
.layout--seniors .section-main .content-download a:before,
.layout--seniors .section-main .content-downloads a:before,
.layout--seniors .section-main .ce_rsce_links a:before,
.layout--seniors .section-links .content-hyperlink a:before,
.layout--seniors .section-links .content-download a:before,
.layout--seniors .section-links .content-downloads a:before,
.layout--seniors .section-links .ce_rsce_links a:before {
  background-position: 0 -14px;
  background-size: 30.3333333333px 42px;
}
.layout--relatives .section-main .content-hyperlink a:before,
.layout--relatives .section-main .content-download a:before,
.layout--relatives .section-main .content-downloads a:before,
.layout--relatives .section-main .ce_rsce_links a:before,
.layout--relatives .section-links .content-hyperlink a:before,
.layout--relatives .section-links .content-download a:before,
.layout--relatives .section-links .content-downloads a:before,
.layout--relatives .section-links .ce_rsce_links a:before {
  background-position: 0 -28px;
  background-size: 30.3333333333px 42px;
}
.layout--experts .section-main .content-hyperlink a:before,
.layout--experts .section-main .content-download a:before,
.layout--experts .section-main .content-downloads a:before,
.layout--experts .section-main .ce_rsce_links a:before,
.layout--experts .section-links .content-hyperlink a:before,
.layout--experts .section-links .content-download a:before,
.layout--experts .section-links .content-downloads a:before,
.layout--experts .section-links .ce_rsce_links a:before {
  background-position: 0 -21px;
  background-size: 30.3333333333px 42px;
}
.layout--doctors .section-main .content-hyperlink a:before,
.layout--doctors .section-main .content-download a:before,
.layout--doctors .section-main .content-downloads a:before,
.layout--doctors .section-main .ce_rsce_links a:before,
.layout--doctors .section-links .content-hyperlink a:before,
.layout--doctors .section-links .content-download a:before,
.layout--doctors .section-links .content-downloads a:before,
.layout--doctors .section-links .ce_rsce_links a:before {
  background-position: 0 -35px;
  background-size: 30.3333333333px 42px;
}
.layout--news .section-main .content-hyperlink a:before,
.layout--news .section-main .content-download a:before,
.layout--news .section-main .content-downloads a:before,
.layout--news .section-main .ce_rsce_links a:before,
.layout--news .section-links .content-hyperlink a:before,
.layout--news .section-links .content-download a:before,
.layout--news .section-links .content-downloads a:before,
.layout--news .section-links .ce_rsce_links a:before {
  background-position: 0 -14px;
  background-size: 30.3333333333px 42px;
}
.section-main .content-hyperlink--file a:before,
.section-main .content-download a:before,
.section-main .content-downloads a:before,
.section-links .content-hyperlink--file a:before,
.section-links .content-download a:before,
.section-links .content-downloads a:before {
  background: url("../../images/download-sprite.svg") left 0px no-repeat;
  width: 22.6666666667px;
  height: 17px;
  background-size: 22.6666666667px 68px;
  content: "";
  top: 17px;
  left: 6px;
}
.layout--seniors .section-main .content-hyperlink--file a:before,
.layout--seniors .section-main .content-download a:before,
.layout--seniors .section-main .content-downloads a:before,
.layout--seniors .section-links .content-hyperlink--file a:before,
.layout--seniors .section-links .content-download a:before,
.layout--seniors .section-links .content-downloads a:before {
  background-position: 0 0px;
  background-size: 22.6666666667px 68px;
}
.layout--relatives .section-main .content-hyperlink--file a:before,
.layout--relatives .section-main .content-download a:before,
.layout--relatives .section-main .content-downloads a:before,
.layout--relatives .section-links .content-hyperlink--file a:before,
.layout--relatives .section-links .content-download a:before,
.layout--relatives .section-links .content-downloads a:before {
  background-position: 0 -34px;
  background-size: 22.6666666667px 68px;
}
.layout--experts .section-main .content-hyperlink--file a:before,
.layout--experts .section-main .content-download a:before,
.layout--experts .section-main .content-downloads a:before,
.layout--experts .section-links .content-hyperlink--file a:before,
.layout--experts .section-links .content-download a:before,
.layout--experts .section-links .content-downloads a:before {
  background-position: 0 -17px;
  background-size: 22.6666666667px 68px;
}
.layout--doctors .section-main .content-hyperlink--file a:before,
.layout--doctors .section-main .content-download a:before,
.layout--doctors .section-main .content-downloads a:before,
.layout--doctors .section-links .content-hyperlink--file a:before,
.layout--doctors .section-links .content-download a:before,
.layout--doctors .section-links .content-downloads a:before {
  background-position: 0 -51px;
  background-size: 22.6666666667px 68px;
}
.layout--news .section-main .content-hyperlink--file a:before,
.layout--news .section-main .content-download a:before,
.layout--news .section-main .content-downloads a:before,
.layout--news .section-links .content-hyperlink--file a:before,
.layout--news .section-links .content-download a:before,
.layout--news .section-links .content-downloads a:before {
  background-position: 0 0px;
  background-size: 22.6666666667px 68px;
}
.section-main .ce_rsce_links,
.section-links .ce_rsce_links {
  margin: 0 -20px;
  padding: 48px 20px 28px;
}
.section-main .ce_rsce_links + .ce_rsce_links,
.section-links .ce_rsce_links + .ce_rsce_links {
  border-top: 1px solid #aaa;
}
.section-main .ce_rsce_links ul,
.section-main .ce_rsce_links li,
.section-links .ce_rsce_links ul,
.section-links .ce_rsce_links li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.section-main .ce_rsce_links p,
.section-links .ce_rsce_links p {
  margin: 1em 0;
}
.section-main .ce_rsce_links .image_container,
.section-links .ce_rsce_links .image_container {
  padding: 20px 0;
  text-align: center;
}
.section-main .ce_rsce_links .image_container img,
.section-links .ce_rsce_links .image_container img {
  width: auto;
  height: auto;
  max-height: 220px;
  border: 1px solid #f1f1f1;
}
.section-main .ce_rsce_links a,
.section-links .ce_rsce_links a {
  border-bottom-color: #d3d3d3 !important;
}
.section-main .ce_rsce_links a.file:before,
.section-links .ce_rsce_links a.file:before {
  background: url("../../images/download-sprite.svg") left 0px no-repeat;
  width: 22.6666666667px;
  height: 17px;
  background-size: 22.6666666667px 68px;
  content: "";
  top: 17px;
  left: 6px;
}
.layout--seniors .section-main .ce_rsce_links a.file:before,
.layout--seniors .section-links .ce_rsce_links a.file:before {
  background-position: 0 0px;
  background-size: 22.6666666667px 68px;
}
.layout--relatives .section-main .ce_rsce_links a.file:before,
.layout--relatives .section-links .ce_rsce_links a.file:before {
  background-position: 0 -34px;
  background-size: 22.6666666667px 68px;
}
.layout--experts .section-main .ce_rsce_links a.file:before,
.layout--experts .section-links .ce_rsce_links a.file:before {
  background-position: 0 -17px;
  background-size: 22.6666666667px 68px;
}
.layout--doctors .section-main .ce_rsce_links a.file:before,
.layout--doctors .section-links .ce_rsce_links a.file:before {
  background-position: 0 -51px;
  background-size: 22.6666666667px 68px;
}
.layout--news .section-main .ce_rsce_links a.file:before,
.layout--news .section-links .ce_rsce_links a.file:before {
  background-position: 0 0px;
  background-size: 22.6666666667px 68px;
}
.section-main .ce_rsce_links li:first-child a,
.section-links .ce_rsce_links li:first-child a {
  border-top: 1px solid #d3d3d3;
}
@media (min-width: 767px) {
  .section-main .content-hyperlink a,
  .section-main .content-download a,
  .section-main .content-downloads a,
  .section-main .ce_rsce_links a,
  .section-links .content-hyperlink a,
  .section-links .content-download a,
  .section-links .content-downloads a,
  .section-links .ce_rsce_links a {
    padding: 19px 0 16px 60px;
    font-size: 20px;
    line-height: 1.625;
  }
  .section-main .content-hyperlink a:before,
  .section-main .content-download a:before,
  .section-main .content-downloads a:before,
  .section-main .ce_rsce_links a:before,
  .section-links .content-hyperlink a:before,
  .section-links .content-download a:before,
  .section-links .content-downloads a:before,
  .section-links .ce_rsce_links a:before {
    background: url("../../images/link-sprite.svg") 0 0px no-repeat;
    width: 39px;
    height: 9px;
    background-size: 39px 54px;
    content: "";
    top: 31px;
  }
  .layout--seniors .section-main .content-hyperlink a:before,
  .layout--seniors .section-main .content-download a:before,
  .layout--seniors .section-main .content-downloads a:before,
  .layout--seniors .section-main .ce_rsce_links a:before,
  .layout--seniors .section-links .content-hyperlink a:before,
  .layout--seniors .section-links .content-download a:before,
  .layout--seniors .section-links .content-downloads a:before,
  .layout--seniors .section-links .ce_rsce_links a:before {
    background-position: 0 -18px;
    background-size: 39px 54px;
  }
  .layout--relatives .section-main .content-hyperlink a:before,
  .layout--relatives .section-main .content-download a:before,
  .layout--relatives .section-main .content-downloads a:before,
  .layout--relatives .section-main .ce_rsce_links a:before,
  .layout--relatives .section-links .content-hyperlink a:before,
  .layout--relatives .section-links .content-download a:before,
  .layout--relatives .section-links .content-downloads a:before,
  .layout--relatives .section-links .ce_rsce_links a:before {
    background-position: 0 -36px;
    background-size: 39px 54px;
  }
  .layout--experts .section-main .content-hyperlink a:before,
  .layout--experts .section-main .content-download a:before,
  .layout--experts .section-main .content-downloads a:before,
  .layout--experts .section-main .ce_rsce_links a:before,
  .layout--experts .section-links .content-hyperlink a:before,
  .layout--experts .section-links .content-download a:before,
  .layout--experts .section-links .content-downloads a:before,
  .layout--experts .section-links .ce_rsce_links a:before {
    background-position: 0 -27px;
    background-size: 39px 54px;
  }
  .layout--doctors .section-main .content-hyperlink a:before,
  .layout--doctors .section-main .content-download a:before,
  .layout--doctors .section-main .content-downloads a:before,
  .layout--doctors .section-main .ce_rsce_links a:before,
  .layout--doctors .section-links .content-hyperlink a:before,
  .layout--doctors .section-links .content-download a:before,
  .layout--doctors .section-links .content-downloads a:before,
  .layout--doctors .section-links .ce_rsce_links a:before {
    background-position: 0 -45px;
    background-size: 39px 54px;
  }
  .layout--news .section-main .content-hyperlink a:before,
  .layout--news .section-main .content-download a:before,
  .layout--news .section-main .content-downloads a:before,
  .layout--news .section-main .ce_rsce_links a:before,
  .layout--news .section-links .content-hyperlink a:before,
  .layout--news .section-links .content-download a:before,
  .layout--news .section-links .content-downloads a:before,
  .layout--news .section-links .ce_rsce_links a:before {
    background-position: 0 -18px;
    background-size: 39px 54px;
  }
  .section-main .content-hyperlink--file a:before,
  .section-main .content-download a:before,
  .section-main .content-downloads a:before,
  .section-links .content-hyperlink--file a:before,
  .section-links .content-download a:before,
  .section-links .content-downloads a:before {
    background: url("../../images/download-sprite.svg") left 0px no-repeat;
    width: 32px;
    height: 24px;
    background-size: 32px 96px;
    content: "";
    top: 21px;
  }
  .layout--seniors .section-main .content-hyperlink--file a:before,
  .layout--seniors .section-main .content-download a:before,
  .layout--seniors .section-main .content-downloads a:before,
  .layout--seniors .section-links .content-hyperlink--file a:before,
  .layout--seniors .section-links .content-download a:before,
  .layout--seniors .section-links .content-downloads a:before {
    background-position: 0 0px;
    background-size: 32px 96px;
  }
  .layout--relatives .section-main .content-hyperlink--file a:before,
  .layout--relatives .section-main .content-download a:before,
  .layout--relatives .section-main .content-downloads a:before,
  .layout--relatives .section-links .content-hyperlink--file a:before,
  .layout--relatives .section-links .content-download a:before,
  .layout--relatives .section-links .content-downloads a:before {
    background-position: 0 -48px;
    background-size: 32px 96px;
  }
  .layout--experts .section-main .content-hyperlink--file a:before,
  .layout--experts .section-main .content-download a:before,
  .layout--experts .section-main .content-downloads a:before,
  .layout--experts .section-links .content-hyperlink--file a:before,
  .layout--experts .section-links .content-download a:before,
  .layout--experts .section-links .content-downloads a:before {
    background-position: 0 -24px;
    background-size: 32px 96px;
  }
  .layout--doctors .section-main .content-hyperlink--file a:before,
  .layout--doctors .section-main .content-download a:before,
  .layout--doctors .section-main .content-downloads a:before,
  .layout--doctors .section-links .content-hyperlink--file a:before,
  .layout--doctors .section-links .content-download a:before,
  .layout--doctors .section-links .content-downloads a:before {
    background-position: 0 -72px;
    background-size: 32px 96px;
  }
  .layout--news .section-main .content-hyperlink--file a:before,
  .layout--news .section-main .content-download a:before,
  .layout--news .section-main .content-downloads a:before,
  .layout--news .section-links .content-hyperlink--file a:before,
  .layout--news .section-links .content-download a:before,
  .layout--news .section-links .content-downloads a:before {
    background-position: 0 0px;
    background-size: 32px 96px;
  }
  .section-main .ce_rsce_links,
  .section-links .ce_rsce_links {
    clear: both;
    margin: 0;
    padding: 50px 0;
  }
  .section-main .ce_rsce_links .image_container,
  .section-links .ce_rsce_links .image_container {
    float: left;
    width: 40%;
    padding: 0.5em 0;
  }
  .section-main .ce_rsce_links .image_container img,
  .section-links .ce_rsce_links .image_container img {
    max-width: 90%;
    max-height: 410px;
  }
  .section-main .ce_rsce_links .content,
  .section-links .ce_rsce_links .content {
    margin-left: 42%;
  }
  .section-main .ce_rsce_links .content h3,
  .section-links .ce_rsce_links .content h3 {
    margin-top: 0;
  }
  .section-main .ce_rsce_links a.file:before,
  .section-links .ce_rsce_links a.file:before {
    background: url("../../images/download-sprite.svg") left 0px no-repeat;
    width: 32px;
    height: 24px;
    background-size: 32px 96px;
    content: "";
    top: 21px;
  }
  .layout--seniors .section-main .ce_rsce_links a.file:before,
  .layout--seniors .section-links .ce_rsce_links a.file:before {
    background-position: 0 0px;
    background-size: 32px 96px;
  }
  .layout--relatives .section-main .ce_rsce_links a.file:before,
  .layout--relatives .section-links .ce_rsce_links a.file:before {
    background-position: 0 -48px;
    background-size: 32px 96px;
  }
  .layout--experts .section-main .ce_rsce_links a.file:before,
  .layout--experts .section-links .ce_rsce_links a.file:before {
    background-position: 0 -24px;
    background-size: 32px 96px;
  }
  .layout--doctors .section-main .ce_rsce_links a.file:before,
  .layout--doctors .section-links .ce_rsce_links a.file:before {
    background-position: 0 -72px;
    background-size: 32px 96px;
  }
  .layout--news .section-main .ce_rsce_links a.file:before,
  .layout--news .section-links .ce_rsce_links a.file:before {
    background-position: 0 0px;
    background-size: 32px 96px;
  }
}

.news-navigation {
  margin: 2em auto !important;
}
.news-navigation ul,
.news-navigation li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.news-navigation ul {
  width: 100%;
  border-bottom: 1px solid #294669;
}
.news-navigation li {
  margin-bottom: 10px;
  padding: 0 15px;
  text-align: center;
}
.news-navigation a,
.news-navigation strong {
  display: inline-block;
  color: #294669;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
.news-navigation a:hover,
.news-navigation a.trail,
.news-navigation a.tab-active,
.news-navigation strong {
  border-bottom-color: #294669;
}
@media (min-width: 767px) {
  .news-navigation ul {
    display: flex;
    justify-content: center;
  }
  .news-navigation li {
    margin-bottom: 0;
  }
  .news-navigation a,
  .news-navigation strong {
    display: block;
    font-size: 16px;
    border-bottom-width: 5px;
  }
}
@media (min-width: 1265px) {
  .news-navigation a,
  .news-navigation strong {
    min-width: 200px;
    padding: 8px 15px;
    font-size: 22px;
    line-height: 54px;
  }
}

.mod_newslist {
  text-align: center;
}

.news-list {
  text-align: left;
}
.news-list__item {
  margin-bottom: 60px;
}
.news-list__headline {
  margin: 0 !important;
}
.news-list__headline a {
  text-decoration: none;
}
.news-list__meta {
  color: #294669;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.news-list__category {
  position: relative;
  font-weight: 400;
}
.news-list__category::before {
  margin: 0 10px 0 8px;
  content: "|";
}
.news-list__teaser p {
  margin: 0;
}
.news-list__link {
  position: relative;
  display: inline-block;
  margin-top: 1em;
  padding-left: 50px;
  color: #294669;
  font-weight: 400;
  font-size: 0.8em;
}
.news-list__link:before {
  background: url("../../images/link-sprite.svg") left -18px no-repeat;
  width: 39px;
  height: 9px;
  background-size: 39px 54px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -5px;
}
@media (min-width: 767px) {
  .news-list--columns {
    columns: 2;
    column-gap: 60px;
  }
  .news-list__item {
    width: 50%;
  }
  .news-list--columns .news-list__item {
    width: auto;
    break-inside: avoid;
    page-break-inside: avoid;
  }
  .news-list__meta {
    font-size: 18px;
    line-height: 37px;
  }
}