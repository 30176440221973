@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.section-links {
    background: map.get(color.$default, light);
    @include color.layout(color, font);

    &__inside {
        position: relative;
        padding: 40px 20px 100px;
    }

    h1 {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 19px;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100px;
            height: 2px;
            content: '';
            @include color.layout(background, font);
        }
    }

    @include media.screen(tablet) {
        .mod_article__inside {
            display: flex;
            flex-wrap: wrap;
            padding-top: 120px;
        }

        h1 {
            position: absolute;
            top: 70px;
            margin: 0 15px;
            font-size: 30px;
        }

        .content-hyperlink,
        .content-download,
        .content-text {
            float: left;
            width: 50%;
            padding: 0 15px;
        }
    }

    @include media.screen(desktop) {
        &__inside {
            max-width: 1260px;
            margin: 0 auto;
        }
    }
}
